import React, { useState, useEffect } from 'react'

import { Link, useParams } from 'react-router-dom'
import ReactPlayer from 'react-player'
import { Typography, Box, Stack } from '@mui/material'
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Videos } from './'
import { fetchFromAPI } from '../utils/fetchFromAPI'
import thumpicon from './Images/126473.png';

const VideoDetail = () => {
    const [videoDetail, setVideoDetail] = useState([])
    const [videos, setVideos] = useState([]);


    const { id } = useParams()

    useEffect(() => {
        fetchFromAPI(`videos?part=snippet,statistics&id=${id}`)
            .then((data) => setVideoDetail(data.items[0]))

        fetchFromAPI(`search?part=snippet&relatedToVideoId=${id}&type=video`)
            .then((data) => setVideos(data.items))


    }, [id]);
    console.log(videoDetail)


    const vanalyzer = (videoDetail) => {
        var viewc = videoDetail?.statistics?.viewCount;
        if (viewc > 1000 && viewc < 1000000) {
            viewc = viewc / 1000 + 'K'
        } else if (viewc > 1000000 && viewc < 10000000000) {
            viewc = (viewc / 1000000).toFixed(2) + 'M'
        } else if (viewc > 1000000000) {
            viewc = (viewc / 1000000000).toFixed(2) + 'B'
        }


        return viewc
    }

    const lanalyzer = (videoDetail) => {
        var likeC = videoDetail?.statistics?.likeCount;
        if (likeC > 1000 && likeC < 1000000) {
            likeC = likeC / 1000 + 'K'
        } else if (likeC > 1000000 && likeC < 10000000000) {
            likeC = (likeC / 1000000).toFixed(2) + 'M'
        } else if (likeC > 1000000000) {
            likeC = (likeC / 1000000000).toFixed(2) + 'B'
        }


        return likeC

    }
    if (!videoDetail?.snippet) return 'Loading...'
    const { snippet: { title, channelId, channelTitle, description }, statistics: { viewCount, likeCount } } = videoDetail;

    return (
        <Box minHeight="95vh">
            <Stack direction={{ xs: 'column', md: 'row' }}>
                <Box flex={1}>
                    <Box sx={{ width: '100%', top: '86px' }}>
                        <ReactPlayer url={`https://www.youtube.com/watch?v=${id}`} className="react-player" controls />
                        <Typography color='#fff' variant='h5' fontWeight='bold' p={2}>
                            {title}
                        </Typography>

                        <Stack direction='row' justifyContent="space-between"
                            sx={{ color: '#fff' }} py={1} px={2}>
                            <Link to={`/channel/${channelId}`}>
                                <Typography variant={{ md: 'h6' }} color="#fff">
                                    {channelTitle}
                                    <CheckCircleIcon sx={{ fontSize: "12px", color: "gray", ml: "5px" }} />
                                </Typography>
                            </Link>
                            <Stack direction="row" gap="20px" alignItems="center">
                                <Typography variant="body1" sx={{ opacity: 0.7 }}>
                                    {vanalyzer(videoDetail)} views
                                </Typography>
                                <Typography variant="body1" sx={{ opacity: 0.7 }}>
                                    {lanalyzer(videoDetail)} likes
                                </Typography>
                            </Stack>

                        </Stack>
                        <Typography color="gray" py={1} px={2}>
                            {description}
                        </Typography>
                    </Box>
                </Box>
                <Box px={2} py={{ md: 1, xs: 5 }} justifyContent='center' alignItems='center'>
                    <Videos videos={videos} direction="column" />

                </Box>
            </Stack>
        </Box>
    )
}

export default VideoDetail